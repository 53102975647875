import {
  Client as HiveSignerClient,
  sign as encode,
  sendOperation
} from "hivesigner";

// Replace these values with your own
const CLIENT_ID = "leofinance";
const REDIRECT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/login"
    : "https://inleo.io/login"; // todo: dinamik

export const hivesigner = new HiveSignerClient({
  app: "leofinance",
  callbackURL: REDIRECT_URL
});

export function createSignToken(): void {
  const loginUrl = hivesigner.getLoginURL(CLIENT_ID, REDIRECT_URL);

  // Open Hivesigner login page
  window.location.replace(loginUrl);
}

export async function vote(
  client: any,
  account: string,
  authorPerm: string,
  weight: number
): Promise<void> {
  const voteOperation = [
    "vote",
    {
      voter: account,
      author: authorPerm.split("/")[0],
      permlink: authorPerm.split("/")[1],
      weight: weight * 100
    }
  ];

  await hivesigner.broadcast([voteOperation], client.auth);
}

export async function broadcast(
  client: any,
  key: string,
  operations: any[]
): Promise<void> {
  return await hivesigner.broadcast(operations);
}

export async function custom_json(operation: ["string", any]): Promise<void> {
  sendOperation(operation, { callback: `https://inleo.io` }, (err, result) => {
    console.log(err, result);
  });
}

